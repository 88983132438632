import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
    return (
        <Route {...rest} render={
            props => {
                //rt(user)
                if (user == true) {
                    return <Component {...rest} {...props} />
                }
                else {
                    return <Redirect to={'/'} />
                }

            }
        } />
    )
}

export default ProtectedRoute;