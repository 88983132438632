import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes/routes';
import 'fontsource-roboto';
import './style.css';   //local style


ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById('root')
);