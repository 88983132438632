import { createMuiTheme } from '@material-ui/core/styles';
import fondo from '../img/fondo.jpg'


const theme = createMuiTheme({
    palette: {
        secondary: {
            light: '#D46A6A',
            main: '#b71c1c',
            dark: '#AA3939',
            contrastText: '#FFFFFF',
        },
        background: {
            paper: '#f5f5f5',
            default: '#f5f5f5'
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundImage: fondo,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                    height: "100%",
                },
            },
        },
    },

})

export default theme;