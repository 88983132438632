import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';

//import CircularProgress from '@material-ui/core/CircularProgress';
import RollingProgress from '../img/rollingProgress.svg'

// const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const DialogLoding = ({ open, onClose }) => {

  const classes = useStyles();
  // const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose();
  };

  // const handleListItemClick = (value) => {
  //     onClose(value);
  // };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Espere un momento ... </DialogTitle>
      <List align="center">
        {/* <CircularProgress disableShrink /> */}
        <img src={RollingProgress} alt="pullman" height="80px" />


        {/* {emails.map((email) => (
          <ListItem button onClick={() => handleListItemClick(email)} key={email}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={email} />
          </ListItem>
        ))} */}


      </List>
    </Dialog>
  );
};

export default DialogLoding;