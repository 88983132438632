import React, { useState, useEffect } from 'react'
import { BrowserRouter, Switch, Route,Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom';

import Login from '../pages/Login';
import Descarga from '../pages/Descarga';
import ProtectedRoute from './ProtectedRoute';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../css/temaConfig'



function Routes() {
  const history_2 = useHistory();


  const [user, setUser] = useState(false)

  const handleLogin = async param => {
    await setUser(true);
  }

  const handleLogout = () => {
    //alert('aqui lohoutd');
    setUser(false);
  }





  return (
    <BrowserRouter>
      <Switch>
        <ThemeProvider theme={theme} >
          <Route exact path="/" handleLogin={handleLogin} render={props => <Login {...props} user={user.toString()} handleLogin={handleLogin} />} />
          <ProtectedRoute exact path="/descargascp" user={user} component={Descarga} />
          <Redirect exact to="/" />
        </ThemeProvider>

      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
