import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import TablaNomina from './TablaNomina';
import LogoP from '../img/enviospullman.svg';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import swal from '@sweetalert/with-react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {/* <Link color="inherit" href="https://pullman.mx/">Pullman de Morelos </Link> */}
            {' '}{new Date().getFullYear()}{'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    palette: {
        primary1Color: "rgba(183, 28, 28, 0.76)",
        primary2Color: "rgba(158, 158, 158, 0.5)",
        primary3Color: "#616161",
        accent1Color: "#616161",
        accent2Color: "#616161"
    },

    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        textColor: "#ffffff",
        //height: 60,
        titleFontWeight: 400,
        //padding: 0,
        backgroundColor: "rgba(183, 28, 28, 0.76)",
    },
    appBarTER: {
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                textColor: "#ffffff",
                //height: 60,
                titleFontWeight: 400,
                //padding: 0,
                backgroundColor: "rgba(94, 96, 100, 1)",
            },

    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));






const Descarga = (props) => {
//    const _EmpresaID = sessionStorage.getItem('EmpresaID');
    const classes = useStyles();
    const [flagStyleNav, setFlagStyleNav] = useState(true);
    
    // useEffect(() => {
        
    //     //Obtiene el item EmpresaID
    //     const empresaid = sessionStorage.getItem('EmpresaID');
    //     empresaid == 24 || empresaid == 25 ? setFlagStyleNav(false) : setFlagStyleNav(true);

    // }, []);


    const [open, setOpen] = React.useState(false);
    const moonLanding = new Date();
    const anio = moonLanding.getFullYear();
    const anio_anterior = anio-1;

    const [anioNomina, setAnioNomina] = useState(anio);
    const [openR, setOpenR] = React.useState(false); // Para Modal Reset Password

    const handleChange = async seganio => {
        await setAnioNomina(seganio)
    };

    const handleClickOpenR = () => {
        setOpenR(true);
    };

    const handleCloseR = () => {
        setOpenR(false);
    };


    const handleDrawerOpen = () => {
        setOpen(true);
    };
    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };

    const Salir = () => {
        sessionStorage.removeItem('RFC');
        sessionStorage.removeItem('Nombre');
        sessionStorage.removeItem('Paterno');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('trabid');
        sessionStorage.removeItem('EmpresaID');
        window.location.replace("/")
    }


    const onPickError = (value, subti) => {
        swal({
            title: `${value}`, 
            text: `${subti}`,
            icon: "error",
            button: "Aceptar",
            dangerMode: true,
        })
            .then((value) => {
                //window.location.reload();
            });
    }

    const onPick = (value,subti) => {
        swal({
            title: `${value}`, 
            text: `${subti}`,
            icon: "success",
            button: "Aceptar",
            dangerMode: true,
        })
            .then((value) => {
                //window.location.reload();
            });

    }


    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={  clsx(classes.appBar, open && classes.appBarShift) }>
            
                <Toolbar className={classes.appBarTER}>
                     Paquetería
                    {/* <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        // onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                    
                    </IconButton> */}
                    <Typography component="subtitle2" variant="subtitle2" color="inherit" noWrap className={classes.title}>
                    {/* <img src={LogoP} alt="pullman" width="100px" /> */}
                    </Typography>
                    {/* <IconButton color="inherit" onClick={() => handleClickOpenR()}>
                        <AccountCircleIcon /> 
                        <Typography component="subtitle2" variant="subtitle2" color="inherit" noWrap className={classes.title}>
                            Contraseña
                        </Typography>
                    </IconButton> */}

                    <IconButton color="inherit" onClick={() => Salir()}>
                        <ExitToAppIcon />
                        <Typography component="subtitle2" variant="subtitle2" color="inherit" noWrap className={classes.title}>
                            Salir
                        </Typography>
                    </IconButton>
                </Toolbar>
            </AppBar>
            {/* <Drawer
                variant="permanent"
                classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}
                open={false}
            >
                <Divider />
                <Divider />
                <a onClick={() => handleChange(anio)} >
                    <ListItem autoFocus={true} >
                        <ListItemText primary={anio} />
                    </ListItem>
                </a>
                <Divider />
                <a onClick={() => handleChange(anio_anterior)}>
                    <ListItem autoFocus={false} >
                        <ListItemText primary={anio_anterior} />
                    </ListItem>
                </a>
                <Divider />
            </Drawer> */}
            
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <TablaNomina anio={anioNomina}  flag={flagStyleNav}/>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
};

export default Descarga;